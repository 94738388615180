import { graphql } from 'gatsby'
import React from 'react'
import { BlogContainer } from '../components/BlogContainer'
import Layout from '../components/Layout';
import { StyledLink } from '../components/Link'
import { PostDate } from '../components/PostDate'
import { PostTitle } from '../components/PostTitle'

function Tags(props) {
    const posts = props.data.allMarkdownRemark.edges
    const { tag } = props.pageContext
    return (
        <Layout>
            <BlogContainer>
                <PostTitle>{`Available posts in ${tag}`}</PostTitle>
                {posts.map(({ node }, i) => (
                    <div key={node.fields.slug}>
                        <PostDate>{node.frontmatter.date}</PostDate>
                        <PostTitle>
                            <StyledLink to={node.fields.slug} key={i}>
                                {node.frontmatter.title}
                            </StyledLink>
                        </PostTitle>
                    </div>
                ))}
            </BlogContainer>
        </Layout>
    )
}

export default Tags

export const query = graphql`
    query TagsQuery($tag: String!) {
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { eq: $tag } } }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
